<template>
  <div class="start-journey">
    <div class="start-journey__container">
      <div class="start-journey__container__content">
        <div class="start-journey__container__content--logos">
          <SimpleLandingLogo :corporate="corporate" />
        </div>
        <h2 class="kds-typography-display-large">
          {{ $t('b2b__landing_page__start_journey_us__title') }}
        </h2>
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('b2b__landing_page__start_journey_us__description')"
        />
        <!--eslint-enable-->
        <div class="start-journey__container__content--cta">
          <BaseButton
            ref="ctaButton"
            class="start-journey__container__cta"
            data-qa="submit"
            @click="handleCTAButtonClick"
          >
            {{ ctaBtnTitle }}
            <template #append>
              <ArrowRightIcon />
            </template>
          </BaseButton>
        </div>
      </div>
      <img
        class="start-journey__container__image"
        :src="image"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import SimpleLandingLogo from '@us/app/components/SimpleLandingLogo.vue'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'
import imageMskEn from '@shared/assets/img/landing-page-main-img-en-US.jpg'
import imageMskEs from '@shared/assets/img/landing-page-main-img-es-US.jpg'

const props = defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  receiveCTAButtonClientRect: {
    type: Function,
    default: () => {},
  },
})

const store = useStore()

const ctaButton = ref()
const corporate = computed(() => store.getters.corporate)
const lang = computed(() => store.getters.lang)
const image = computed(() => {
  if (lang.value === 'es-US') return imageMskEs
  return imageMskEn
})

onMounted(() => {
  props.receiveCTAButtonClientRect(() =>
    ctaButton?.value?.button?.getBoundingClientRect(),
  )
})
</script>

<style lang="scss">
.start-journey {
  padding: 40px 10px 0 10px;

  @include media-up(lg) {
    padding: 10px;
  }

  @include media-up(xl) {
    display: flex;
    padding: 60px 0;
  }

  &__container {
    @include flex-container(column);

    max-width: 1200px;
    margin: auto;

    @include media-up(lg) {
      @include flex-container(row, space-between);
      grid-gap: 100px;
    }

    &__content {
      @include flex-container(column, flex-start, lg);

      &--cta {
        .base-button {
          display: flex;
          @include media-down(lg) {
            width: 100%;
          }
        }
      }
    }

    &__cta {
      flex-grow: 1;
    }

    &__image {
      width: 80%;
      margin: auto;
      object-fit: contain;
      height: 327px;

      @include media-up(lg) {
        width: 590px;
        height: 492px;
        margin: 0;

        @media (max-height: 700px) {
          height: 430px;
        }
      }
    }
  }
}
</style>
