<template>
  <div :class="backgroundColorClass">
    <div class="program-overview">
      <h2
        class="program-overview__header"
        v-html="$t('b2b__landing_page__program_overview__title')"
      />
      <div
        class="program-overview__intro"
        v-html="$t('b2b__landing_page__program_overview__description')"
      />
      <div class="program-overview-sections">
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="program-overview-section"
        >
          <div
            :class="['program-overview-section__banner', card.class]"
            v-html="card.text"
          />
          <div
            class="program-overview-section__info"
            v-html="card.description"
          />
        </div>
      </div>
      <div class="program-overview__cta">
        <BaseButton @click="handleCTAButtonClick">
          {{ ctaBtnTitle }}
          <template #append>
            <ArrowRightIcon />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'petite-vue-i18n'
import BaseButton from '@shared/components/BaseButton.vue'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'

const { t } = useI18n()

defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  backgroundColorClass: {
    type: String,
    default: 'kds-color-background-base',
  },
})

const cards = [
  {
    class: 'program-overview-section__banner--coaching',
    text: t('b2b__landing_page__generic__coaching'),
    description: t(
      'b2b__landing_page__program_overview__coaching__description',
    ),
  },
  {
    class: 'program-overview-section__banner--exercise-de',
    text: t('b2b__landing_page__generic__exercise'),
    description: t(
      'b2b__landing_page__program_overview__exercise__description',
    ),
  },
  {
    class: 'program-overview-section__banner--relaxation',
    text: t('b2b__landing_page__generic__relaxation'),
    description: t(
      'b2b__landing_page__program_overview__relaxation__description',
    ),
  },
  {
    class: 'program-overview-section__banner--learning',
    text: t('b2b__landing_page__generic__learning'),
    description: t(
      'b2b__landing_page__program_overview__learning__description',
    ),
  },
]
</script>

<style lang="scss">
:root {
  --base-button--width: unset;
}

.program-overview {
  padding: 31px 24px 49px;

  &__header {
    margin: 0;
    padding-bottom: 13px;
    text-align: center;

    @include kaia-typography-h5($font-weight-bold);
  }

  &__intro {
    text-align: center;

    @include kaia-typography-p2($font-weight-normal);
  }

  &-section {
    padding-top: 49px;

    &__banner {
      display: block;
      margin: 0 auto;
      height: 275px;
      padding-top: 24px;
      box-sizing: border-box;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: auto 100%;
      text-align: center;
      color: $midnight;

      @include kaia-typography-p2($font-weight-bold);

      &--coaching {
        color: $white;
        padding-left: 43px;

        background-image: url('@shared/assets/img/coaching_4x.png');
      }

      &--exercise-de {
        background-image: url('@shared/assets/img/exercise_4x.png');

        font-size: 14px;
      }

      &--relaxation {
        background-image: url('@shared/assets/img/relaxation_4x.png');
      }

      &--learning {
        background-image: url('@shared/assets/img/learning_4x.png');
      }
    }

    &__info {
      @include kaia-typography-p2($font-weight-normal);

      text-align: center;
      padding-top: 20px;
    }
  }
}

.program-overview {
  @media (min-width: $size-small) {
    padding: 48px 24px;
  }

  @media (min-width: $size-medium) {
    padding: 72px 54px;
  }

  @media (min-width: $size-large) {
    padding: 72px 82px;
  }

  @media (min-width: $size-large-x) {
    padding: 96px 82px;
  }

  @media (min-width: $size-large-xx) {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__header {
    @media (min-width: $size-medium) {
      padding-bottom: 18px;

      @include kaia-typography-h4($font-weight-bold);
    }

    @media (min-width: $size-large-x) {
      @include kaia-typography-h3($font-weight-bold);

      padding-bottom: 24px;
    }
  }

  &__intro {
    @media (min-width: $size-medium) {
      padding: 0 70px;

      @include kaia-typography-h6($font-weight-normal);
    }

    @media (min-width: $size-large) {
      padding: 0 150px;
    }
  }

  &-sections {
    @media (min-width: $size-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    @media (min-width: $size-large-x) {
      justify-content: space-between;
    }
  }

  &-section {
    @media (min-width: $size-medium) {
      padding-top: 36px;
      flex-basis: 50%;
    }

    @media (min-width: $size-large-x) {
      padding-top: 48px;
      width: 254px;
      flex-basis: auto;
      padding-bottom: 0;
    }

    &__banner {
      @media (min-width: $size-medium) {
        height: 354px;
        padding-top: 33px;

        @include kaia-typography-p3($font-weight-bold);
      }

      @media (min-width: $size-large-x) {
        width: 100%;
      }

      &--coaching {
        @media (min-width: $size-medium) {
          padding-left: 60px;
        }
      }

      &--exercise-de {
        @media (min-width: $size-medium) {
          font-size: 17px;
        }
      }
    }

    &__info {
      @media (min-width: $size-medium) {
        @include kaia-typography-p3($font-weight-normal);

        padding: 18px 15px 0;
      }

      @media (min-width: $size-large) {
        padding: 18px 65px 0;
      }

      @media (min-width: $size-large-x) {
        padding: 24px 0 0;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>
