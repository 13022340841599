<template>
  <div class="simple-landing-layout">
    <SimpleStartJourney
      v-if="showSimpleStartJourney"
      :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__title')"
      :corporate="corporate"
      :handle-c-t-a-button-click="handleSimpleStartJourneyCTAClick"
      :is-german="isGerman"
    />
    <SimpleCheckIn
      v-if="showSimpleCheckIn"
      :handle-c-t-a-button-click="handleSimpleCheckInCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
    <SimplePlanOutline
      v-if="showSimplePlanOutline"
      :handle-c-t-a-button-click="handleSimplePlanOutlineCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
    <SimpleTestimonial
      v-if="showSimpleTestimonial"
      :handle-c-t-a-button-click="handleSimpleTestimonialCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import SimpleStartJourney from '@us/app/components/SimpleStartJourney.vue'
import SimpleCheckIn from '@us/app/components/SimpleCheckIn.vue'
import SimplePlanOutline from '@us/app/components/SimplePlanOutline.vue'
import SimpleTestimonial from '@us/app/components/SimpleTestimonial.vue'
import ROUTE from '@us/app/router/appModule/names'
import { useI18n } from 'petite-vue-i18n'
import { setPageTitle } from '@shared/utils.js'

const pages = {
  SIMPLE_START_JOURNEY: 1,
  SIMPLE_CHECK_IN: 2,
  SIMPLE_PLAN_OUTLINE: 3,
  SIMPLE_TESTIMONIAL: 4,
}

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const currentPage = ref(pages.SIMPLE_START_JOURNEY)
const lang = computed(() => store.getters.lang)
const corporate = computed(() => store.getters.corporate)
const utmData = computed(() => store.getters.utmData)

const showSimpleStartJourney = computed(
  () => currentPage.value === pages.SIMPLE_START_JOURNEY,
)
const showSimpleCheckIn = computed(
  () => currentPage.value === pages.SIMPLE_CHECK_IN,
)
const showSimplePlanOutline = computed(
  () => currentPage.value === pages.SIMPLE_PLAN_OUTLINE,
)
const showSimpleTestimonial = computed(
  () => currentPage.value === pages.SIMPLE_TESTIMONIAL,
)

const isGerman = computed(
  () => lang.value.indexOf('de-') === 0 || lang.value === 'de',
)

const handleSimpleStartJourneyCTAClick = () => {
  setTimeout(() => router.push({ name: ROUTE.B2B_SIMPLE_CHECK_IN }), 400)
}

const handleSimpleCheckInCTAClick = (choice) => {
  store.commit('checkInStatus', choice)
  setTimeout(() => router.push({ name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE }), 400)
}

const handleSimplePlanOutlineCTAClick = () => {
  setTimeout(() => router.push({ name: ROUTE.B2B_SIMPLE_TESTIMONIAL }), 400)
}

const handleSimpleTestimonialCTAClick = () => {
  if (corporate.value) {
    router.push({ name: ROUTE.B2B_SELECT_VERIFICATION_METHOD })
  } else {
    router.push({ name: ROUTE.B2B_PARTNERS })
  }
}

const updateCurrentPage = (to) => {
  if (
    (to.name === ROUTE.B2B_SIMPLE_PLAN_OUTLINE ||
      to.name === ROUTE.B2B_SIMPLE_TESTIMONIAL) &&
    !store.getters.checkInStatus
  ) {
    router.push({ name: ROUTE.B2B_SIMPLE_CHECK_IN })
  }

  if (to.name === ROUTE.B2B_INFORMATION) {
    currentPage.value = pages.SIMPLE_START_JOURNEY
  } else if (to.name === ROUTE.B2B_SIMPLE_CHECK_IN) {
    currentPage.value = pages.SIMPLE_CHECK_IN
  } else if (to.name === ROUTE.B2B_SIMPLE_PLAN_OUTLINE) {
    currentPage.value = pages.SIMPLE_PLAN_OUTLINE
  } else if (to.name === ROUTE.B2B_SIMPLE_TESTIMONIAL) {
    currentPage.value = pages.SIMPLE_TESTIMONIAL
  }
}

watch(
  route,
  (to) => {
    updateCurrentPage(to)
    requestAnimationFrame(() => {
      document.documentElement.scrollTop = 0
    })
  },
  { immediate: true },
)

watch(
  corporate,
  (corporate) => {
    setPageTitle(
      [
        corporate?.title ? `${corporate.title} | ` : '',
        t('b2b__landing_page__generic__page_title'),
      ].join(''),
    )
  },
  { immediate: true },
)

onMounted(() => {
  store.dispatch('utmsToStore', window.location.search)

  requestAnimationFrame(() => {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0
    } else if (document.body.scrollTop > 0) {
      document.body.scrollTop = 0
    }
  })
})
</script>

<style lang="scss">
html,
body {
  height: 100% !important;
}

.app {
  display: block !important;
  height: 100% !important;
}

.simple-landing-layout {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  margin: 0 auto;
  background-color: $blue-strong;

  @media (min-width: $size-large) {
    max-width: 100%;
  }
}
</style>
