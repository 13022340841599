<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="three-steps">
    <div class="three-steps__container">
      <div class="three-steps__container__cta">
        <h1
          class="three-steps__container__cta--title"
          v-html="$t('b2b__landing_page__landing_3_steps__title')"
        />
        <div class="three-steps__container__cta--button">
          <BaseButton @click="handleCTAButtonClick">
            {{ ctaBtnTitle }}
            <template #append>
              <ArrowRightIcon />
            </template>
          </BaseButton>
        </div>
      </div>
      <div class="three-steps__container__steps">
        <div
          v-for="({ image, title }, index) in cards"
          :key="index"
          class="three-steps__container__steps--step"
        >
          <img
            :src="image"
            :alt="`step ${index}`"
            class="three-steps__container__steps--step--icon"
          />
          <p
            class="three-steps__container__steps--step--title"
            v-html="$t(title)"
          />
        </div>
      </div>

      <div class="three-steps__container__mobile-cta">
        <BaseButton @click="handleCTAButtonClick">
          {{ ctaBtnTitle }}
          <template #append>
            <ArrowRightIcon />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
  <!--eslint-enable-->
</template>

<script setup>
import { computed } from 'vue'
import BaseButton from '@shared/components/BaseButton.vue'
import ArrowRightIcon from '@shared/assets/icon/ArrowRightIcon.vue'
import accountSetupImage from '@shared/assets/img/account_setup.svg'
import downloadImage from '@shared/assets/img/download.svg'
import firstDayImage from '@shared/assets/img/first_day.svg'
import checkupImage from '@shared/assets/img/checkup.svg'
import env from '@shared/env.js'

defineProps({
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  ctaBtnTitle: {
    type: String,
    default: '',
  },
})

const cards = computed(() =>
  [
    {
      image: accountSetupImage,
      title: 'b2b__landing_page__landing_3_steps__card_one__title',
    },
    {
      image: downloadImage,
      title: 'b2b__landing_page__landing_3_steps__card_two__title',
    },
    {
      image: env.isMsk ? firstDayImage : checkupImage,
      title: 'b2b__landing_page__landing_3_steps__card_three__title',
    },
    env.isCopd && {
      image: firstDayImage,
      title: 'b2b__landing_page__landing_3_steps__card_four__title',
    },
  ].filter(Boolean),
)
</script>

<style lang="scss" scoped>
.three-steps {
  padding: 40px 10px;
  background: $blue-subtle;

  @include media-up(lg) {
    padding: 96px 10px;
  }

  &__container {
    @extend %container;
    margin: 0 auto;
    @include flex-container(column, flex-start, md);

    @include media-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__cta {
      &--title {
        text-align: center;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
        @include kaia-typography-h3($font-weight-bold);

        @include media-up(md) {
          text-align: left;
          max-width: 400px;
        }
      }

      &--button {
        display: none;

        @include media-up(md) {
          display: inline-block;
        }
      }
    }

    &__steps {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 0 50px;
      grid-gap: 70px;
      max-width: 450px;

      @include media-up(md) {
        padding: 0;
        margin: 0;
      }

      &--step {
        display: flex;
        grid-gap: 30px;
        align-items: start;
        position: relative;

        // line
        &:nth-child(n + 2):before {
          position: absolute;
          content: '';
          width: 2px;
          height: 70px;
          background: $sky;
          left: 30px;
          bottom: 100%;
        }

        &--title {
          margin: 2px 0 0 0;
        }

        &--icon {
          height: 60px;
          width: 60px;
        }
      }
    }

    &__mobile-cta {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      @include media-up(md) {
        display: none;
      }
    }
  }
}
</style>
